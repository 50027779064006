<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="1" md="1" sm="1" align-self="center">
        <GoBack link="/products/list" />
      </v-col>

      <v-col cols="12" lg="11" md="11" sm="11" align-self="center">
        <PageNavigation :items="items" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.createCategory')"
          icon="mdi-apple-finder"
        >
          <v-row class="my-2">
            <v-col cols="12" class="pt-0 pb-0 d-flex justify-end">
              <v-btn
                color="green"
                elevation="2"
                link
                to="type"
                @click="handleNewType"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.createCategory") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-treeview
                :items="datas"
                item-children="items"
                transition
                open-on-click
              >
                <template v-slot:append="{ item }">
                  <v-icon @click="addType(item, $event)" color="green" left>
                    mdi-plus
                  </v-icon>
                  <v-icon @click="editType(item, $event)" color="blue">
                    mdi-pencil
                  </v-icon>
                  <v-icon @click="handleDelete(item, $event)" color="red" right>
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <DialogDelete
      :dialogDelete.sync="deleteDialog"
      :deleteObj="typeObj"
      @handleDelete="deleteDepartment"
    />

    <v-dialog persistent v-model="dialog" width="600">
      <v-card>
        <v-card-title class="d-flex justify-start">
          <span class="font-weight-bold subtitle">
            {{ $vuetify.lang.t("$vuetify.newCategory") }}
          </span>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-form ref="form" v-model="valid">
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.categoryName')"
              :rules="rules"
              v-model="typeObj.name"
              outlined
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer />

          <v-btn color="red" outlined @click="handleCancel">
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>

          <v-btn color="green" @click="handleSave">
            {{ $vuetify.lang.t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { goodsTypeList, goodsTypeUpsert, goodsTypeDel } from "@/api/goods";

function changeNullItems(department) {
  if (department.items == null) {
    department.items = [];
  } else {
    for (let i = 0; i < department.items.length; i++) {
      changeNullItems(department.items[i]);
    }
  }
}

export default {
  name: "ProductType",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    GoBack: () => import("@/components/gobackCard"),
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      typeObj: this.newType(),
      rules: [(v) => !!v || "This field is required"],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.goodsType") },
      ],
      datas: [],
      valid: false,
    };
  },
  methods: {
    newType() {
      return {
        id: "",
        parent: "",
        name: "",
        order: 0,
        template_id: "",
        sell_company: [], // 销售主体
      };
    },
    getData() {
      goodsTypeList()
        .then((res) => {
          this.datas = res.items;

          this.datas.forEach((p) => {
            changeNullItems(p);
          });
        })
        .catch((err) => {
          console.log("Product Type List Error", err);
          this.$toast.error("Product Type List Error");
        });
    },
    addType(item, e) {
      e.stopPropagation();

      this.dialog = true;
      this.typeObj.parent = item.id;
    },
    editType(item, e) {
      e.stopPropagation();

      this.dialog = true;
      this.typeObj = item;
    },
    handleDelete(item, e) {
      e.stopPropagation();

      this.typeObj = item;
      this.deleteDialog = true;
    },
    handleCancel() {
      this.dialog = false;

      this.typeObj = this.newType();

      this.resetValidation();
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        this.dialog = false;

        goodsTypeUpsert(this.typeObj)
          .then(() => {
            this.getData();
            this.typeObj = this.newType();
          })
          .catch((err) => {
            console.log("Product Type Upsert Error", err);
            this.$toast.error("ProdcutType Upsert Error");
          });

        this.resetValidation();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async deleteAllChildrenDepartments(delDept) {
      if (delDept.items.length) {
        for (let i = 0; i < delDept.items.length; i++) {
          await this.deleteAllChildrenDepartments(delDept.items[i]);
        }
      }
      await goodsTypeDel(delDept.id);
    },
    async deleteDepartment(item) {
      this.deleteDialog = false;
      await this.deleteAllChildrenDepartments(item);
      this.getData();
    },
    handleNewType() {
      this.dialog = true;
      this.typeObj = this.newType();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>